import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disable"
export default class extends Controller {
  static targets = ["source", "dest"];

  static values = {
    option: String,
  };

  connect() {
    this.toggleState(this.sourceTarget.checked);
  }

  toggle(event) {
    this.toggleState(event.target.checked);
  }

  toggleState() {
    // this.destTargets.forEach((t) => {
    //   if (checked) {
    //     t.disabled = true;
    //   } else {
    //     t.disabled = false;
    //   }
    // });
  }
}
