import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  async connect() {
    const response = await get(this.url);
    if (response.ok) {
      this.element.innerHTML = await response.html;
    } else {
      // eslint-disable-next-line no-console
      console.log("ERROR", response);
    }
  }

  get url() {
    return this.data.get("url");
  }
}
