import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

// Connects to data-controller="embed-iframe"
export default class extends Controller {
  static targets = ["placeholder", "container"];

  static values = {
    urlOrIframe: String,
    userId: String,
    enabled: Boolean,
  };

  connect() {
    if (this.enabledValue) {
      this.show(null, { enableAutoplay: false });
      // TODO: If consent given, reload other iframes on page?
    }
  }

  show(event, { enableAutoplay = true } = {}) {
    if (event) {
      event.preventDefault();
    }

    if (this.userIdValue !== "0") {
      patch(`/api/users/${this.userIdValue}`, {
        body: { user: { embed_consent_given_at: new Date() } },
      });
    }

    if (this.hasPlaceholderTarget) {
      this.placeholderTarget.remove();
    }

    this.containerTarget.insertAdjacentHTML(
      "beforeend",
      this.buildIframe({ enableAutoplay }),
    );
  }

  buildIframe({ enableAutoplay = true }) {
    if (this.embedCodeType(this.urlOrIframeValue) === "iframe") {
      return this.urlOrIframeValue;
    }
    if (this.embedCodeType(this.urlOrIframeValue) === "url") {
      return `
        <iframe src="${this.urlOrIframeValue}" frameborder="0" allow="${
          enableAutoplay ? "autoplay;" : ""
        } fullscreen">
        </iframe>
      `;
    }
    return ``;
  }

  embedCodeType(code) {
    if (this.verifyUrl(code)) {
      return "url";
    }
    if (code.includes("iframe")) {
      return "iframe";
    }
    return "other";
  }

  verifyUrl(url) {
    const res = url.match(/^(http(s)?:\/\/.)/);
    return res !== null;
  }
}
